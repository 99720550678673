<template>
  <el-main class="rh-form">
    <form-fpostgradedit :routetolist="{ name: 'fpostgrads' }" />
  </el-main>
</template>
<script>
import FormFpostgradedit from "@/components/rh/formulaires/FormFpostgradedit";

export default {
  components: { "form-fpostgradedit": FormFpostgradedit },
};
</script>
